<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button style="margin-bottom: 20px" type="primary" icon="el-icon-plus" size="small" @click="goAdd">新增</el-button>

    <el-table :data="list">
      <el-table-column prop="name" label="字典名称" min-width="120"> </el-table-column>
      <el-table-column prop="key" label="key值" min-width="120"> </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="120"> </el-table-column>

      <el-table-column prop="id" label="操作" minwidth="200" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <!-- <el-link style="margin-right: 10px" type="danger" :underline="false" @click="handleDel(row)">删除</el-link> -->
          <el-link style="margin-right: 10px" type="warning" :underline="false" @click="handleAddDictValue(row)"
            >添加字典值</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="page" :limit.sync="pagesize" @pagination="getList" />
    <!-- 新增 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_dict_update" width="width">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="字典名称" prop="name" :rules="rules">
          <el-input style="width: 200px" v-model="formData.name" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="key" prop="key" :rules="rules">
          <el-input
            :disabled="formData.id ? true : false"
            style="width: 200px"
            v-model="formData.key"
            size="small"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input style="width: 200px" v-model="formData.sort" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_dict_update = false">取 消</el-button>
        <el-button type="primary" @click="submitDict">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDictListAPI, getDictDetailAPI, delDictAPI, addDictAPI, editDictAPI } from './api'

export default {
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_dict_update: false,
      list: [],
      total: 0,
      page: 1,
      pagesize: 10,
      formData: {
        name: '',
        key: '',
        sort: 0
      }
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize
      }
      const res = await getDictListAPI(params)
      this.list = res.records || []
      this.total = res.totalRow || 0
    },
    // 新增字典
    goAdd() {
      this.show_dict_update = true
      this.formData = {
        name: '',
        key: '',
        sort: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // 编辑字典
    async handleEdit({ id }) {
      this.show_dict_update = true
      this.$nextTick(async () => {
        this.formData = await getDictDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    // 删除字典
    handleDel({ id }) {
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDictAPI(id).then(() => {
            this.$message.success('删除成功')
            thi.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 提交字典
    submitDict() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editDictAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_dict_update = false
            })
          } else {
            addDictAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_dict_update = false
            })
          }
        }
      })
    },
    handleAddDictValue({ name, key }) {
      this.$router.push({ name: 'DictValueManage', query: { dictCode: key || undefined, dictName: name || undefined } })
    },
    goRemove(id) {
      console.log(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
