import { http } from '@/http/axios.js'

// 字典列表
export function getDictListAPI(params) {
  return http({
    url: `/manage/dict/page`,
    method: 'get',
    params
  })
}

// 字典详情
export function getDictDetailAPI(id) {
  return http({
    url: `/manage/dict/detail`,
    method: 'get',
    params: { id }
  })
}
// 编辑字典
export function editDictAPI(data) {
  return http({
    url: `/manage/dict/update`,
    method: 'POST',
    data
  })
}
// 新增字典
export function addDictAPI(data) {
  return http({
    url: `/manage/dict/create`,
    method: 'post',
    data
  })
}
// del字典
export function delDictAPI(data) {
  return http({
    url: `/manage/dict/delete`,
    method: 'post',
    data
  })
}
// 字典值列表
export function getDictValuesListAPI(parentKey) {
  return http({
    url: `/manage/dict/value/page`,
    method: 'get',
    params: { parentKey }
  })
}
// add字典值
export function addDictValueAPI(data) {
  return http({
    url: `/manage/dict/value/create`,
    method: 'post',
    data
  })
}
// edit字典值
export function editDictValueAPI(data) {
  return http({
    url: `/manage/dict/value/update`,
    method: 'POST',
    data
  })
}
// del字典值
export function delDictValueAPI(id) {
  return http({
    url: `/manage/dict/value/delete?id=${id}`,
    method: 'POST',
    data: {}
  })
}
// 字典值详情
export function getDictValueDetailAPI(id) {
  return http({
    url: `/manage/dict/value/detail`,
    method: 'GET',
    params: { id }
  })
}
